import styles from './Modal.module.scss';

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useKeyDown } from 'shared/hooks/useKeyDown';

export interface ModalProps extends PropsWithChildren {
  visible: boolean;
  className?: string;
  onClose?: () => void;
  style?: Object;
  modalClassName?: string;
}

const Modal = ({ children, onClose, visible, className, style, modalClassName }: ModalProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const closeModal = useCallback(() => {
    if (typeof onClose === 'function') {
      document.body.style.overflow = 'auto';
      onClose();
    }
  }, [onClose]);

  useClickOutside({
    ref: contentRef,
    cb: closeModal,
  });

  useKeyDown('Escape', closeModal);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    }
  }, [visible]);

  useEffect(() => {
    return () => void (document.body.style.overflow = 'auto');
  }, []);

  return createPortal(
    // @ts-ignore
    visible && (
      <div className={cn(styles.modal, modalClassName)}>
        <div className={cn(styles.content, className)} ref={contentRef} style={style}>
          {children}
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
