import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'shared/redux-store';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import cn from 'classnames';
import { hide, ModalTypes } from 'slices/modal';
import Modal from 'components/Modal/Modal';
import Button, { Variant } from 'components/Button/Button';
import {
  useCreateTaskMutation,
  useCreateTaskWithAssessmentMutation,
  useInitialAssessmentMutation,
  useTaskAssessmentWithAnswerMutation,
} from 'services/tasks';
import { QAndADto, TaskResponseDto, TaskStatus } from 'dtos';
import styles from './TaskFormulatingModal.module.scss';
import TaskInfo from 'pages/task/components/TaskInfo';
import isEmpty from 'lodash/isEmpty';

function TaskFormulatingModal(): JSX.Element | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.TaskFormulating]
  );

  const [inputText, setInputText] = useState<string>('');
  const [task, setTask] = useState<TaskResponseDto | undefined>(modalData.task);
  const [isNeedInitialAssessment, setIsNeedInitialAssessment] = useState<boolean>();
  const [qAndAVisibility, setQAndAVisibility] = useState(false);

  const [createTask, { isLoading: isCreating }] = useCreateTaskMutation();
  const [createTaskWithAssessment, { isLoading: isCreatingTaskWithAssessment }] =
    useCreateTaskWithAssessmentMutation();
  const [initialAssessment, { isLoading: isInitialAssessment }] = useInitialAssessmentMutation();
  const [assessmentWithAnswer, { isLoading: isAssessmentWithAnswer }] =
    useTaskAssessmentWithAnswerMutation();

  const isFlowBlocked = isCreating || isCreatingTaskWithAssessment || isInitialAssessment || isAssessmentWithAnswer

  useEffect(() => {
    setTask(modalData.task);
    setIsNeedInitialAssessment(modalData.isNeedInitialAssessment);
  }, [modalData]);

  useEffect(() => {
    if (isNeedInitialAssessment === true) {
      onNextButtonClick(task);
      setIsNeedInitialAssessment(false);
    }
  }, [isNeedInitialAssessment]);

  const onInputChange = (evt: ChangeEvent<HTMLTextAreaElement>): void => {
    setInputText(evt.target.value);
  };

  const closeModal = (): void => {
    setInputText('');
    setTask(undefined);
    if (modalData.onCloseModal) {
      modalData.onCloseModal();
    }
    dispatch(hide({ id: ModalTypes.TaskFormulating }));
  };

  const onCreateDraftTaskClick = async () => {
    if (task === undefined) {
      await toast.promise(createTask(inputText), {
        loading: t('status.saving'),
        success: () => {
          return <b>{t('status.success')}</b>;
        },
        error: (error) => {
          const errorMessage =
            error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    }
    closeModal();
  };

  const onNextButtonClick = async (modalDataTask?: TaskResponseDto) => {
    const currentTask = task || modalDataTask;

    try {
      let taskAssessmentResponse;
      if (currentTask === undefined) {
        taskAssessmentResponse = await createTaskWithAssessment(inputText).unwrap();
      } else if (currentTask.currentQuestion === undefined || isNeedInitialAssessment === true) {
        taskAssessmentResponse = await initialAssessment(currentTask.id).unwrap();
      } else {
        taskAssessmentResponse = await assessmentWithAnswer({
          taskId: currentTask.id,
          answer: inputText,
        }).unwrap();
      }
      setTask(taskAssessmentResponse.task);
      setInputText('');
    } catch (e) {
      Sentry.captureException(e);
      alert(t('status.smthWrong'));
    }
  };

  const onConfirmButtonClick = () => {
    closeModal();
  };

  if (!visible) {
    return null;
  }

  const canCreateTask = inputText.length > 5;
  const isTaskFormulated = task?.status === TaskStatus.ReadyForWork;

  let title: string | undefined;
  let question: string | undefined;
  let topButtonText: string;
  let onTopButtonClick;
  let questionsAndAnswers: QAndADto[] | undefined;

  if (task === undefined) {
    question = t('modal.taskFormulation.initialCase');
    topButtonText = canCreateTask
      ? t('modal.taskFormulation.createDraftButton')
      : t('modal.taskFormulation.cancelButton');
    onTopButtonClick = canCreateTask ? onCreateDraftTaskClick : closeModal;
  } else {
    title = task.title || task.originalTask;
    topButtonText = t('modal.taskFormulation.createDraftButton');
    onTopButtonClick = closeModal;
    if (!isInitialAssessment || isNeedInitialAssessment) {
      question = task.currentQuestion;
      questionsAndAnswers = task.qandA;
    }
  }

  const onQAndAClick = () => {
    setQAndAVisibility(!qAndAVisibility);
  }

  return (
    <Modal
      visible={visible}
      onClose={isFlowBlocked ? () => {} : closeModal}
      modalClassName={styles.modal}
      className={cn(styles.modalContent, isTaskFormulated && styles.confirmationTaskModal)}
    >
      {isTaskFormulated ? (
          <>
            <TaskInfo task={task} showOriginalDescription={true}/>
            <Button
              className={styles.confirmButton}
              variant={Variant.Minimal}
              onClick={onConfirmButtonClick}
              disabled={isFlowBlocked}
            >
              {t('modal.taskFormulation.confirmButton')}
            </Button>
          </>
      ) : (
        <>
          <Button
            className={cn(styles.topButton)}
            variant={Variant.Minimal}
            onClick={onTopButtonClick}
            isLoading={isCreating}
            disabled={isFlowBlocked}
          >
            {topButtonText}
          </Button>
          <div className={styles.caseBlock}>
            {title && <div className={styles.title}>{title}</div>}
            {question && (
              <div className={styles.questionBlock}>
                <div className={styles.question}>{question}</div>
                {task?.score && <div className={styles.score}>{task?.score}%</div>}
              </div>
            )}
          </div>
          {!isEmpty(questionsAndAnswers) && (
            <div className={styles.questionsAndAnswers}>
              <div className={styles.qAndATitle} onClick={onQAndAClick}>
                {t('modal.taskFormulation.qAndA')}
              </div>
              <ul className={cn(styles.questionsAndAnswersList, !qAndAVisibility && styles.hidden)}>
                {questionsAndAnswers!!.map((qAndA) => {
                  return (
                    <>
                      <li key={Math.random()} className={cn(styles.qAndAItem)}>
                        {qAndA.question}
                      </li>
                      <li key={Math.random()} className={cn(styles.qAndAItem, styles.answerItem)}>
                        {qAndA.answer}
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          )}
          <textarea
            className={styles.textarea}
            value={inputText}
            onChange={onInputChange}
            autoComplete="off"
            autoFocus={true}
            disabled={isCreating || isCreatingTaskWithAssessment || isInitialAssessment || isAssessmentWithAnswer}
          />
          <Button
            className={styles.downButton}
            variant={Variant.Minimal}
            onClick={() => {onNextButtonClick();}}
            disabled={!canCreateTask || isFlowBlocked}
            isLoading={
              isCreatingTaskWithAssessment || isInitialAssessment || isAssessmentWithAnswer
            }
          >
            {t('modal.taskFormulation.nextButton')}
          </Button>
        </>
      )}
    </Modal>
  );
}

export default TaskFormulatingModal;
