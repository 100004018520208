export const HOME = '/';
export const HOMERU = '/ru';
export const HOMEEN = '/en';
export const MAINPAGE = '/mainpage';
export const MAINPAGERU = '/ru/mainpage';
export const MAINPAGEEN = '/en/mainpage';
export const RESEARCH = '/research';
export const RESEARCHRU = '/ru/research';
export const RESEARCHEN = '/en/research';
export const BLOG = '/blog';
export const BLOGRU = '/ru/blog';
export const BLOGEN = '/en/blog';
export const JOIN = '/join';
export const JOINRU = '/ru/join';
export const JOINEN = '/en/join';
export const INSTALL = '/install';
export const INSTALLRU = '/ru/install';
export const INSTALLEN = '/en/install';

export const LOGIN = '/login';
export const INSTALL_OLD = '/install-old';
export const INVITATION = '/invitation';
export const FAIL_SCREEN = '/fail';
export const EXCHANGE = '/exchange/:id';
export const EXCHANGE_MAIN = '/exchange-main';
export const FEE_RANGE_DEFAULTS = '/fee-ranges-default';
export const NOT_FOUND = '/404';
export const FEES = '/fees/:id';

export const FRIENDS = '/friends';
export const FRIEND = FRIENDS + '/:id';

export const CONTACTS = '/contacts';
export const INVITATIONS = CONTACTS + '/invitations';

export const PROFILE = '/profile';
export const MAINTENANCE = PROFILE + '/maintenance';
export const SUBSCRIPTIONS = PROFILE + '/subscriptions';
export const NOTIFICATIONS = PROFILE + '/notifications';

export const ASSISTANTS = PROFILE + '/assistants';
export const REDEEM_INVITATION = PROFILE + '/redeem-invitation';
export const PAYMENTS = '/payments';
export const PAYMENT = PAYMENTS + '/:id';
export const EXCHANGE_RATES = PROFILE + '/exchange-rates';
export const EXCHANGE_RATES_MY = PROFILE + '/my-exchange-rates';
export const EXCHANGE_RATES_BY_PROVIDER =
  PROFILE + '/exchange-rates/:providerId';

export const BALANCE_ASSISTANT = '/balance/:id/assistants';
export const BALANCE_HISTORY = '/balance/:id/history';
export const PAYMENT_CHAT_HISTORY = '/payments/:id/chat-history';

export const PUBLIC = '/public/:id';

export const TASK_MAIN = '/task';
export const TASK = '/task/:id';
export const TASKS = '/tasks';

