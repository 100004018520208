import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { TaskResponseDto, TaskStatus } from 'dtos';
import Button, { Variant } from 'components/Button/Button';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import styles from './MainTask.module.scss';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useNavigate } from 'react-router-dom';
import { useStartWorkingOnTaskMutation } from 'services/tasks';

export interface MainTaskProps {
  readyForWorkTasks: TaskResponseDto[];
  isLoadingTaskList: boolean;
  onPrioritizeButtonClick: () => void;
  isPrioritizing: boolean;
  openTaskFormulationModal: () => void;
}

const MainTask = (props: MainTaskProps) => {
  const {
    readyForWorkTasks,
    isLoadingTaskList,
    onPrioritizeButtonClick,
    isPrioritizing,
    openTaskFormulationModal,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prioritizedTasks = readyForWorkTasks.filter((task) => task.isPrioritized);
  const mainTask = prioritizedTasks.find((task) => task.priority === 1);
  const [startWorking] = useStartWorkingOnTaskMutation();

  const openFocusPage = async () => {
    if (mainTask) {
      if (mainTask.status === TaskStatus.ReadyForWork) {
        await startWorking(mainTask.id);
      }
      navigate(`/task/${mainTask.id}`);
    }
  };

  if (isLoadingTaskList) {
    return (
      <>
        <div className={styles.background} />
        <div className={styles.mainTask}>
          <SkeletonWithWrapper height={40} count={2} />
        </div>
      </>
    );
  }

  if (isPrioritizing) {
    return (
      <>
        <div className={styles.background} />
        <div className={styles.mainTask}>
          <div className={styles.header}>
            <div className={styles.aiPrioritized}>
              <AIPrioritizedIcon className={styles.aiPrioritizedIcon} />
              <div>
                <div className={styles.aiPrioritizedTitle}>
                  {t('page.task.mainTask.aiPrioritizedTitle')}
                </div>
                <div className={styles.aiPrioritizedDescription}>
                  {t('page.task.mainTask.aiPrioritizedDescription')}
                </div>
              </div>
            </div>
          </div>
          <SkeletonWithWrapper height={40} count={2} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.background} />
      <div className={styles.mainTask} onClick={openFocusPage}>
        {!isEmpty(readyForWorkTasks) ? (
          mainTask ? (
            <>
              <div className={styles.header}>
                <div className={styles.aiPrioritized}>
                  <AIPrioritizedIcon className={styles.aiPrioritizedIcon} />
                  <div>
                    <div className={styles.aiPrioritizedTitle}>
                      {t('page.task.mainTask.aiPrioritizedTitle')}
                    </div>
                    <div className={styles.aiPrioritizedDescription}>
                      {t('page.task.mainTask.aiPrioritizedDescription')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainTaskTitle}>{mainTask?.title}</div>
              <div className={styles.mainTaskDescription}>{mainTask?.motivation}</div>
            </>
          ) : (
            <>
              <div className={styles.mainTaskTitle}>
                {t('page.task.mainTask.notPrioritizedTasksTitle') + readyForWorkTasks.length}
              </div>
              <div className={styles.mainTaskDescription}>
                {t('page.task.mainTask.notPrioritizedTasksDescription')}
              </div>
              <Button
                variant={Variant.Minimal}
                className={styles.mainTaskButton}
                onClick={onPrioritizeButtonClick}
              >
                {t('page.task.mainTask.prioritizeButton')}
              </Button>
            </>
          )
        ) : (
          <>
            <div className={styles.mainTaskTitle}>{t('page.task.mainTask.noTasksTitle')}</div>
            <div className={styles.mainTaskDescription}>
              {t('page.task.mainTask.noTaskDescription')}
            </div>
            <Button
              variant={Variant.Minimal}
              className={styles.mainTaskButton}
              onClick={openTaskFormulationModal}
            >
              {t('page.task.mainTask.createButton')}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default MainTask;
