import { useTranslation } from 'react-i18next';
import styles from './ExchangerBlock.module.scss';

import { ExchangeTypeAndMethodSelects } from '../ExchangeTypeAndMethodSelects';
import { ExchangeCurrencySelect } from '../ExchangeCurrencySelect';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { ExchangeAmountInput } from '../ExchangeAmountInput';
import { ExchangerSubcategories } from '../ExchangerSubcategories';
import { ExchangeSlider } from '../ExchangeSlider';
import { ExchangerSkuParams } from '../ExchangerSkuParams';
import { ExchangerMeasureUnits } from '../ExchangerMeasureUnits';

import { useExchangerStore, UseExchangerStoreReturn } from '../../store';
import { ExchangerScope } from '../../types';

export function ExchangerBlock(props: ExchangerWidgetScopeProps) {
  const { t } = useTranslation();
  const { providerId, userId, isPublic, customOfferMode } = props;
  const { isInitialization, activeOption }: UseExchangerStoreReturn = useExchangerStore(
    providerId,
    userId
  );

  if (activeOption === 'Currency' || props.scope === ExchangerScope.Sell) {
    return (
      <div className={styles.field}>
        {!isInitialization && (
          <h3 className={styles.title}>
            {t(
              props.scope === ExchangerScope.Buy
                ? (customOfferMode ? 'labels.clientGets' : 'labels.youGet')
                : activeOption !== 'Currency'
                  ? 'labels.total'
                  : (customOfferMode ? 'labels.clientGives' : 'labels.youGive')
            )}
          </h3>
        )}
        <div className={styles.col}>
          <div className={styles.row}>
            <ExchangeAmountInput {...props} />
            <ExchangeCurrencySelect {...props} />
            <ExchangeTypeAndMethodSelects {...props} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.col}>
        {!isPublic && (
          <div className={styles.row}>
            <ExchangeTypeAndMethodSelects {...props} />
          </div>
        )}
        <ExchangerSubcategories {...props} />
        <ExchangeSlider {...props} />
        <ExchangerSkuParams {...props} />
        <ExchangerMeasureUnits {...props} />
      </div>
    </>
  );
}
