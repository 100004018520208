import { TaskResponseDto } from 'dtos';
import cn from 'classnames';
import styles from './Task.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface TaskProps {
  task: TaskResponseDto;
  isOpacity?: boolean;
  isCompleted?: boolean;
}

const Task = ({task, isOpacity, isCompleted}: TaskProps) => {
  const navigate = useNavigate();

  const onTaskClick = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();
    navigate(`/task/${evt.currentTarget.dataset.taskId}`);
  };

  return (
    <div
      className={cn(styles.task, isOpacity && styles.opacity, isCompleted && styles.completed)}
      data-task-id={task.id}
      onClick={onTaskClick}
      key={task.id}
    >
      {task.title}
    </div>
  );
}

export default Task;
