import styles from './InfiniteContactList.module.scss';

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { InfiniteList } from 'components/InfiniteList';
import ContactCard from 'components/ContactsList/ContactCard';

import { useGetInfiniteFriends } from './api/useGetInfiniteFriends';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { useQueryClient } from '@tanstack/react-query';

type InfiniteContactListProps = {
  searchString?: string;
};

export function InfiniteContactList(props: InfiniteContactListProps): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const { searchString } = props;
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfiniteFriends(searchString);

  const contacts = useMemo(() => {
    const allContacts = data?.pages.map((p) => p.content) || [];
    return allContacts.flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    queryClient.removeQueries(['friends'], { exact: false })
  }, []);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.contacts}
      loaderItem={<SkeletonWithWrapper height={64} />}
      emptyListPlaceholder={t('page.friends.empty')}
    >
      {contacts.map((c, idx) => (
        <li key={c.userDto.id} ref={indexOfLastListElement === idx ? ref : undefined}>
          <ContactCard contact={c} />
        </li>
      ))}
    </InfiniteList>
  );
}
