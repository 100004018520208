import styles from './ContactCard.module.scss';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import truncate from 'lodash/truncate';

import Button, { Variant } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import ContactRelationIndicator from '../ContactRelationIndicator';

import type { ContactDto } from 'dtos';

import Amount from 'components/Amount';

import { Avatar, AvatarProps, AvatarSize } from 'shared/ui/Avatar/Avatar';

import { getUserName, getUserPhoto, isBalanceType } from 'utils';
import { ModalTypes, open } from 'slices/modal';
import { BalanceType } from 'widgets/CreateBalanceTransactionModal';

export interface ContactCardProps {
  contact: ContactDto;
}

function ContactCard({ contact }: ContactCardProps): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    userDto,
    contactBalanceSummary: { balanceSummaryList, plusNumber },
  } = contact;
  const userName = useMemo(() => getUserName(userDto), [userDto]);
  const userPhoto = useMemo(() => getUserPhoto(userDto), [userDto]);
  const contactRelationType = useMemo(() => userDto.contactRelationType, [userDto]);

  const avatarProps = useMemo<AvatarProps>(() => {
    return {
      username: userName,
      profilePictureUrl: userPhoto,
      dotIndicator: <ContactRelationIndicator relationType={contactRelationType} />,
      size: AvatarSize.Payment,
    };
  }, [userName, userPhoto, contactRelationType]);

  const openTransactionModal = (evt: React.MouseEvent<HTMLButtonElement>): void => {
    evt.preventDefault();
    const type: string | undefined = evt.currentTarget.dataset.balanceType;
    if (isBalanceType(type)) {
      dispatch(
        open({
          id: ModalTypes.CreateBalanceTransaction,
          data: { contact: userDto, type },
        })
      );
    }
  };

  return (
    <Link to={`/friends/${contact.userDto.id}`} className={styles.card}>
      <Avatar {...avatarProps} />

      <div className={styles.wrap}>
        <div className={styles.inner}>
          <div className={styles.username}>{userName}</div>

          <div className={styles.btns}>
            <Button
              variant={Variant.Success}
              onClick={openTransactionModal}
              data-balance-type={BalanceType.Receive}
              className={styles.btn}
            >
              {t('button.receive')}
            </Button>
            <Button
              variant={Variant.White}
              onClick={openTransactionModal}
              data-balance-type={BalanceType.Send}
              className={styles.btn}
            >
              {t('button.send')}
            </Button>
          </div>
        </div>

        <ul className={styles.balances}>
          {balanceSummaryList.map((b) => (
            <li key={b.currencyCode}>
              <Amount
                value={b.value}
                code={truncate(b.currencyCode, { length: 10, omission: '…' })}
                sign
                compact
                colored
                locale="en-US"
              />
            </li>
          ))}

          {plusNumber > 0 && (
            <li className={styles.balance}>
              <Icon name={IconName.Cash} width={14} height={9} className={styles.icon} />

              <span> +{plusNumber}</span>
            </li>
          )}
        </ul>
      </div>
    </Link>
  );
}

export default React.memo(ContactCard);
