import styles from './index.module.scss';

import { FC, useState } from 'react';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import Rate from 'components/Rate/Rate';
import { useGoodsInfo } from 'widgets/ExchangerWidget/hooks/useGetGoodsInfo';
import { GoodsDto, MeasureUnitDto } from 'dtos';
import { Slider } from 'shared/ui/Slider';

const range = { min: 1, max: 1000 };

type MeasureUnitDtoWithId = MeasureUnitDto & {
  amount: number;
  id: number;
  onChange: (index: number) => (value: number) => void;
};

export const ExchangerMeasureUnits: FC<ExchangerWidgetProps> = (props) => {
  const { providerId, userId, customOfferMode, crossExchangeRates } = props;
  const { currentFormData, calculateExchangePaymentForm, isInitialization, tabs, activeOption } =
    useExchangerStore(providerId, userId, undefined, crossExchangeRates, customOfferMode);
  const goods = tabs?.find(t => t.name === activeOption)?.goods;
  const { currentGood } = useGoodsInfo({
    good: currentFormData?.buyExchangeUnit as GoodsDto,
    cities: goods,
    subcategory: currentFormData?.subcategory,
  });
  const amount = currentFormData?.buyExchangeUnitAmount || 1;
  const measureUnit = currentFormData?.buyExchangeUnit?.measureUnit;
  const amounts = (currentGood?.measureUnits || []).map((mu) => (mu.name === measureUnit ? amount : 1));

  const handleChange = (index: number) => (value: number) => {
    amounts[index] = value;

    calculateExchangePaymentForm({
      buyExchangeUnitAmount: value,
    });
  };

  const currentSlides = currentGood?.measureUnits.map((mu, i) => ({
    ...mu,
    amount: amounts[i],
    onChange: handleChange,
    id: i,
  }));
  const currentSlide = (currentSlides || []).find((m) => m.name === measureUnit);
  const [currentNumber, setCurrentNumber] = useState<number>(currentSlide?.amount || 1)
  const handleChangeSlide = (slide?: MeasureUnitDtoWithId) => {
    if (!slide) {
      return;
    }
    if (customOfferMode) {
      calculateExchangePaymentForm(
        {
          buyExchangeUnitAmount: amounts[slide.id],
          buyExchangeUnit: {
            ...currentFormData?.buyExchangeUnit,
            measureUnit: slide.name,
          },
          sellExchangeUnitAmount: Number(((currentFormData!!.sellExchangeUnitAmount / currentNumber) * amounts[slide.id]).toFixed(2)),
          sellExchangeUnit: currentFormData?.sellExchangeUnit,
        },
        undefined,
        undefined,
        customOfferMode
      );
    } else {
      calculateExchangePaymentForm({
        buyExchangeUnitAmount: amounts[slide.id],
        buyExchangeUnit: {
          ...currentFormData?.buyExchangeUnit,
          measureUnit: slide.name,
        },
      });
    }
    setCurrentNumber(amounts[slide.id])
  };

  if (isInitialization) {
    // FIXME: add skeleton
    return <SkeletonWithWrapper className={styles.list} height={37} />;
  }

  if (!amount || !measureUnit || !currentSlides) {
    return <></>;
  }

  return (
    <Slider
      sliderClassName={currentSlides.length > 1 ? styles.slider : styles.sliderNoActive}
      slideClassName={currentSlides.length > 1 ? styles.slide : ''}
      slides={currentSlides}
      slide={currentSlide}
      onSlideChange={handleChangeSlide}
      slideRenderer={Slide}
    />
  );
};

const Slide = (slide: MeasureUnitDtoWithId) => {
  return (
    <div className={styles.root}>
      <span className={styles.text}>{slide.localizedName}</span>
      <Rate
        value={slide.amount || 1}
        step={1}
        suffix=""
        onChange={slide.onChange(slide.id)}
        className={styles.rate}
        range={range}
        withInput
        withoutDecimals
      />
    </div>
  );
};
