import styles from './BalanceCardInfo.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { Variant } from 'components/Button/Button';
import Amount from 'components/Amount';
import ArrowRightBoldIcon from 'components/icons/ArrowRightBold';

import type { BalanceBucketNoHistoryDto } from 'dtos';
import { BalanceType } from 'widgets/CreateBalanceTransactionModal';

export interface BalanceCardProps extends BalanceBucketNoHistoryDto {
  balanceId: number;
  canTransfer: boolean;
  onCreateBalanceTransaction: React.MouseEventHandler<HTMLButtonElement>;
  onObligationTransfer: React.MouseEventHandler<HTMLButtonElement>;
}

function BalanceCardInfo({
  balanceId,
  shortname,
  totalBalance,
  onCreateBalanceTransaction,
  onObligationTransfer,
  canTransfer,
}: BalanceCardProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.amount}>
        <Amount value={totalBalance} code={shortname} sign colored/>
      </div>

      <div className={styles.btns}>
        <Button
          variant={Variant.Success}
          onClick={onCreateBalanceTransaction}
          data-balance-id={balanceId}
          data-balance-type={BalanceType.Receive}
          data-code={shortname}
          className={styles.btn}
        >
          {t('button.receive')}
        </Button>

        <Button
          variant={Variant.White}
          onClick={onCreateBalanceTransaction}
          data-balance-id={balanceId}
          data-balance-type={BalanceType.Send}
          data-code={shortname}
          className={styles.btn}
        >
          {t('button.send')}
        </Button>

        {canTransfer && (
          <Button
            variant={Variant.White}
            className={styles.obligationsTransferBtn}
            onClick={onObligationTransfer}
            data-balance-id={balanceId}
            data-code={shortname}
            data-value={totalBalance}
          >
            <ArrowRightBoldIcon className={styles.obligationsTransferIcon} />
          </Button>
        )}
      </div>
    </div>
  );
}

export default React.memo(BalanceCardInfo);
