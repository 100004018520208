import styles from '../../ExchangerWidget.module.scss';

import { useMemo } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';

import { Select } from 'components/Select';
import {
  CurrencyInformationResponseDtoV3,
  PaymentFormDtoV5,
  PaymentMethodDto,
} from 'dtos';
import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { CurrencyMethodType, ExchangerScope } from '../../types';
import { useExchangerStore } from '../../store';
import { getBuyOrSellExchangeUnitProp, isBuyScope } from '../../utils';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

interface ExchangeTypeSelectProps extends ExchangerWidgetScopeProps {
  foundCurrency?: CurrencyInformationResponseDtoV3;
  paymentMethod?: PaymentMethodDto;
}

export function ExchangeTypeSelect(
  props: ExchangeTypeSelectProps
): JSX.Element | null {
  const {
    providerId,
    userId,
    paymentMethod,
    foundCurrency,
    scope = ExchangerScope.Buy,
  } = props;
  const {
    currentFormData,
    calculateExchangePaymentForm,
    activeOption,
    isInitialization,
  } = useExchangerStore(providerId, userId);
  const paymentMethodType: CurrencyMethodType | undefined = paymentMethod?.type;

  const { value, values, labels } = useMemo(() => {
    if (isInitialization) {
      return { label: '', labels: [] };
    }

    const methods = map(foundCurrency?.paymentMethods, (m) => {
      return {
        id: m.paymentMethodType,
        label: m.localizedPaymentMethodType,
      }
    });

    const value: string =
        (find(methods, (m) => isEqual(m.id, paymentMethodType)) || head(methods))
          ?.id || '';
    const values = map(methods, (m) => String(m.id)) || [];
    const labels = methods.reduce((acc: Record<string, string>, item) => {

      return {
        ...acc,
        [`${item.id}`]: item.label,
      }
    }, {});

    return { value, values, labels};
  }, [foundCurrency, paymentMethodType, isInitialization]);

  if (isInitialization) {
    // FIXME: skeleton is not visible
    return (
      <SkeletonWithWrapper
        className={styles.select}
        height={21}
        style={{ width: '30%' }}
      />
    );
  }

  const changeExchangeType = (type: string) => {
    const buyOrSellExchangeUnitProp = getBuyOrSellExchangeUnitProp(scope);

    if (currentFormData && foundCurrency) {
      // TODO: Использовать findCurrencyPaymentMethodInfoByTypeAndName и findPaymentMethodByType
      const activeType = foundCurrency.paymentMethods.find((item) => item.paymentMethodType === type);
      const buyOrSellCurrencyData: PaymentFormDtoV5[typeof buyOrSellExchangeUnitProp] =
        {
          ...currentFormData[buyOrSellExchangeUnitProp],
          paymentMethod: {
            ...currentFormData[buyOrSellExchangeUnitProp].paymentMethod!!,
            type: type as CurrencyMethodType,
            name: activeType?.methods[0] || ''
          },
        };

      calculateExchangePaymentForm({
        [buyOrSellExchangeUnitProp]: buyOrSellCurrencyData,
      });
    }
  };

  if (
    !paymentMethodType ||
    (isBuyScope(scope) && activeOption !== 'Currency')
  ) {
    return null;
  }

  if (!value) {
    return null;
  }

  return (
    <Select
      value={value}
      values={values || []}
      labels={labels}
      onChange={changeExchangeType}
      className={styles.select}
      buttonClassName={styles.btn}
    />
  );
}
