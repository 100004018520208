import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { HOME } from 'pages/paths';
import {
  retrieveAccessToken,
  retrieveRefreshToken,
  clearStore,
  removeTokens, setTokens
} from 'shared/lib/auth';
import releaseInfo from '../release-info';
import isEmpty from 'lodash/isEmpty';
import { mutex } from '../utils/mutex';
import checkIsWebApp from '../utils/checkIsWebApp';

const FORBIDDEN = 403;
const NOT_AUTH_ERROR = 401;
// const NOT_FOUND_STATUS = 404;
// const SUCCESS_STATUS = 200;

let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (!error && token) {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'app-version': releaseInfo.version,
  },
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    await mutex.waitForUnlock();
    const token = retrieveAccessToken();
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (
      (error.response?.status === NOT_AUTH_ERROR ||
        error.response?.status === FORBIDDEN) &&
      // @ts-ignore
      !originalRequest._retry
    ) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()

        try {
          const refreshToken = retrieveRefreshToken();
          const isWebApp = checkIsWebApp();

          if (isEmpty(refreshToken) && !isWebApp && window.location.pathname !== HOME) {
            clearStore();
            window.location.replace(HOME);
            return Promise.reject(error);
          }

          if (!isEmpty(refreshToken)) {
            return new Promise(function(resolve, reject) {
              axios
                .post(`${process.env.REACT_APP_API_URL}/refresh-token`, {
                  refreshToken,
                })
                .then(({ data }) => {
                  const { jwt, refreshToken, userId } = data;
                  setTokens({ jwt, refreshToken, userId });

                  axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
                  originalRequest.headers = {
                    ...originalRequest.headers,
                    Authorization: 'Bearer ' + jwt,
                  };
                  processQueue(null, jwt);
                  resolve(axios(originalRequest));
                })
                .catch((err) => {
                  processQueue(err, null);
                  reject(err);
                  window.location.reload();
                  removeTokens();
                })
                .finally(() => {
                  isRefreshing = false;
                });
            });
          } else if (isWebApp) {
            const webAppInitData = window.Telegram.WebApp.initData
            return new Promise(function(resolve, reject) {
              axios
                .post(`${process.env.REACT_APP_API_URL}/webapp/login`, webAppInitData)
                .then(({ data }) => {
                  const { jwt, refreshToken, userId } = data;
                  setTokens({ jwt, refreshToken, userId });

                  axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
                  originalRequest.headers = {
                    ...originalRequest.headers,
                    Authorization: 'Bearer ' + jwt,
                  };
                  processQueue(null, jwt);
                  resolve(axios(originalRequest));
                })
                .catch((err) => {
                  processQueue(err, null);
                  reject(err);
                  removeTokens();
                  sessionStorage.setItem('__telegram__initParams', '{}');
                  window.location.reload();
                })
                .finally(() => {
                  isRefreshing = false;
                });
            });
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock()
        return new Promise(function(resolve, reject) {
          const jwt = retrieveAccessToken();
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: 'Bearer ' + jwt,
          };
          processQueue(null, jwt as any);
          resolve(axios(originalRequest));
        })
      }

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: 'Bearer ' + token,
            };
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      // @ts-ignore
      originalRequest._retry = true;
      isRefreshing = true;
    }
    return Promise.reject(error);
  }
);

export default instance;
