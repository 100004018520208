import { useTranslation } from 'react-i18next';
import { UserId } from 'widgets/ExchangerWidget/types';
import TelegramBotAuth from 'components/TelegramBotAuth';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';

import styles from './styles.module.scss';
import { useCreateSessionMutation } from 'services/payments';
import { UseExchangerStoreReturn, useExchangerStore } from 'widgets/ExchangerWidget';
import { PaymentCreationRequestDtoV3, PaymentFormDtoV5, TargetField, } from 'dtos';
import { formatExchangeCurrencyAmount } from 'widgets/Exchanger/lib';
import { upperCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import getRandomUUID from 'utils/getRandomUUID';
import checkIsAuth from 'utils/checkIsAuth';
import Button, { Variant } from 'components/Button/Button';
import { getUserId } from 'shared/lib/auth';
import { getExchangeUnitDto } from '../../dtos/dtoMapper';

type Props = {
  providerId?: UserId;
};

const UUID_KEY = getRandomUUID();

const ButtonAuthorization = ({ providerId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createSession, { isLoading: isCreating }] = useCreateSessionMutation();
  const exchanger: UseExchangerStoreReturn = useExchangerStore(providerId, null);
  const paymentForm: PaymentFormDtoV5 = exchanger?.currentFormData as PaymentFormDtoV5;
  const isAuth = checkIsAuth();
  const successHandler = async () => {
    const preparedForm: PaymentCreationRequestDtoV3 = {
      providerId: paymentForm.providerId,
      userId: paymentForm.customerId,
      utmTag: exchanger.utmTag,
      buyExchangeUnit: getExchangeUnitDto(paymentForm.buyExchangeUnit, true),
      buyCurrencyAmount: formatExchangeCurrencyAmount(paymentForm.buyExchangeUnitAmount),
      sellExchangeUnit: getExchangeUnitDto(paymentForm.sellExchangeUnit, true),
      sellCurrencyAmount: formatExchangeCurrencyAmount(paymentForm.sellExchangeUnitAmount),
      targetField: `${upperCase(exchanger?.inputTarget || 'buy')}_CURRENCY` as TargetField,
    };

    try {
      const userId = getUserId();
      preparedForm.userId = Number(userId);
      const { sessionKey } = await createSession(preparedForm).unwrap();
      navigate(`/exchange/${sessionKey}`, { state: { sessionKey } });
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  };

  if (exchanger?.isInitialization) {
    return null;
  }

  if (!isAuth) {
    return (
      <TelegramBotAuth
        keyId={UUID_KEY}
        onSuccess={successHandler}
        className={styles.tgButton}
        text={t('button.confirmViaBot')}
      />
    );
  }

  return (
    <Button onClick={successHandler} isLoading={isCreating} className={styles.button} variant={Variant.Telegram}>
      {t('button.confirmViaBot')}
    </Button>
  );
};

export default ButtonAuthorization;
