import styles from './ExchangeSlider.module.scss';

import React, { FC, useMemo } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import head from 'lodash/head';

import { Slider } from 'shared/ui/Slider';
import {
  GoodsDto,
  PaymentFormDtoV5,
  SkuModelDto,
  SkuParameterResponseDto,
} from 'dtos';

import { ExchangerWidgetScopeProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import { ExchangerScope } from '../../types';
import { ExchangeSlide } from '../ExchangeSlide';
import { getBuyOrSellExchangeUnitProp } from '../../utils';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { useGoodsInfo } from 'widgets/ExchangerWidget/hooks/useGetGoodsInfo';
import { getExchangeUnitDto } from 'dtos/dtoMapper';

type SkuModelDtoWithId = SkuModelDto & {
  id: number;
};

export const ExchangeSlider: FC<ExchangerWidgetScopeProps> = (props) => {
  const { providerId, userId, scope = ExchangerScope.Buy } = props;
  const {
    tabs,
    currentFormData,
    activeOption,
    calculateExchangePaymentForm,
    isInitialization,
  } = useExchangerStore(providerId, userId);
  const buyOrSellExchangeUnitProp = getBuyOrSellExchangeUnitProp(scope);
  const goods = tabs?.find(t => t.name === activeOption)?.goods;

  const { currentSubcategory } = useGoodsInfo({
    good: currentFormData?.buyExchangeUnit as GoodsDto,
    cities: goods,
    subcategory: currentFormData?.subcategory,
  });

  const slides = useMemo(() => {
    let list: SkuModelDtoWithId[] = [];
    if (isInitialization) {
      return list;
    }

    if (activeOption) {
      list = map(currentSubcategory?.skuModels || [], (g, idx) => ({
        ...g,
        id: idx,
      }));
    }
    return list;
  }, [activeOption, isInitialization, currentSubcategory]);

  const selectedSlide = useMemo(
    () =>
      find(
        slides,
        (c) => c.modelName === currentFormData?.[buyOrSellExchangeUnitProp]?.name
      ) || head(slides),
    [slides, currentFormData, buyOrSellExchangeUnitProp]
  );

  if (isInitialization) {
    return (
      <SkeletonWithWrapper
        className={styles.slider}
        height={156}
        style={{ margin: '8px 0' }}
      />
    );
  }

  const changeSlide = (slide?: SkuModelDtoWithId) => {
    if (!slide) {
      return;
    }

    if (!currentFormData) {
      return;
    }

    const skuParams = currentFormData[buyOrSellExchangeUnitProp].skuParameters;
    const hasAvailableSkuParams = Array.isArray(slide.availableSkuParameters);

    let newSkuParams: SkuParameterResponseDto[] = [];
    if (hasAvailableSkuParams) {
      newSkuParams = (slide.availableSkuParameters)
        .map(param => {
          const currentSkuParam = skuParams?.find(p => p.name === param.name);
          const hasEqualValue = param.availableValues.find(v => v.value === currentSkuParam?.value);

          return {
            type: param.type,
            name: param.name,
            value: hasEqualValue ? currentSkuParam?.value : head(param.availableValues)?.value,
          } as SkuParameterResponseDto;
        });
    }

    const buyOrSellCurrencyData: PaymentFormDtoV5[typeof buyOrSellExchangeUnitProp] =
      {
        ...currentFormData[buyOrSellExchangeUnitProp],
        skuParameters: hasAvailableSkuParams ? newSkuParams : [],
        name: slide.modelName,
      };

    calculateExchangePaymentForm({
      [buyOrSellExchangeUnitProp]: getExchangeUnitDto(buyOrSellCurrencyData, false),
    });
  };

  if (!selectedSlide) {
    return null;
  }

  return (
    <Slider
      slide={selectedSlide}
      slides={slides}
      onSlideChange={changeSlide}
      slideRenderer={ExchangeSlide}
      sliderClassName={styles.slider}
      slideClassName={styles.slide}
    />
  );
};
