import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { removeTokens, retrieveAccessToken, retrieveRefreshToken, setTokens } from 'shared/lib/auth';
import { JwtTokenResponse } from 'dtos';
import releaseInfo from '../release-info';
import isEmpty from 'lodash/isEmpty';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { mutex } from '../utils/mutex';
import checkIsWebApp from '../utils/checkIsWebApp';


const FORBIDDEN = 403;
const NOT_AUTH_ERROR = 401;

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('App-Version', releaseInfo.version);
    const token = retrieveAccessToken();

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: (isTokenRequired: boolean) => BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (isTokenRequired) => async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> | undefined;
  if (isTokenRequired) {
    if (retrieveAccessToken()) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      result = {
        error: {
          status: NOT_AUTH_ERROR,
          data: 'Not authorized error'
        },
        meta: {
          request: new Request('https://not.a.real.url/')
        }
      };
    }
  } else {
    result = await baseQuery(args, api, extraOptions);
  }

  if (result?.error?.status === NOT_AUTH_ERROR || result?.error?.status === FORBIDDEN) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
        try {
          const refreshToken = retrieveRefreshToken();
          const isWebApp = checkIsWebApp();

          if (!isEmpty(refreshToken)) {
            try {
              const refreshResult = await baseQuery(
                { url: '/refresh-token', body: { refreshToken }, method: 'POST' },
                api,
                extraOptions
              );

              if (refreshResult.data) {
                const { jwt, refreshToken, userId } = (refreshResult.data as JwtTokenResponse);
                setTokens({ jwt, refreshToken, userId });

                // retry the initial query
                result = await baseQuery(args, api, extraOptions);
              } else {
                removeTokens();
                window.location.reload();
              }
            } catch (e) {
              console.log(e);
            }
          } else if (isWebApp) {
            try {
              const webAppInitData = window.Telegram.WebApp.initData
              const webappLoginResult = await baseQuery(
                { url: '/webapp/login', body: webAppInitData, method: 'POST' },
                api,
                extraOptions,
              );
              if (webappLoginResult.data) {
                const { jwt, refreshToken, userId } = webappLoginResult.data as JwtTokenResponse;
                setTokens({ jwt, refreshToken, userId });

                result = await baseQuery(args, api, extraOptions);
              } else {
                removeTokens();
                sessionStorage.setItem('__telegram__initParams', '{}');
                window.location.reload();
              }
            } catch (e) {
              console.log(e);
            }
          }
        } finally {
          release();
        }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result;
};
