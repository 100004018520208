import { TaskResponseDto, TaskStatus } from 'dtos';
import { useTranslation } from 'react-i18next';
import styles from './TaskInfo.module.scss';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import React from 'react';
import AIPrioritized from 'components/AIPrioritized';
import isEmpty from 'lodash/isEmpty';

export interface TaskInfoProps {
  task: TaskResponseDto;
  showOriginalDescription?: boolean;
}

function TaskInfo({ task, showOriginalDescription }: TaskInfoProps): JSX.Element | null {
  const { t } = useTranslation();

  const isToBeFormulated = task.status === TaskStatus.ToBeFormulated;

  return (
    <>
      <div className={styles.task}>
        {task.title && (
          <div className={styles.section}>
            {task.status === TaskStatus.InProgress && (
              <AIPrioritized description={t('page.taskInfo.aiPrioritizedDescription')} darkTheme={true} />
            )}
            <div className={styles.taskTitle}>{task.title}</div>
          </div>
        )}

        {(showOriginalDescription || isToBeFormulated) && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>
                {t('page.taskInfo.originalDescription')}
              </div>
            </div>
            <div className={styles.sectionDescription}>{task.originalTask}</div>
          </div>
        )}

        {task.modifiedTask && !isToBeFormulated && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>
                {t('page.taskInfo.description')}
              </div>
              <AIPrioritizedIcon className={styles.aiIcon} />
              <div className={styles.aiProposed}>
                {t('page.taskInfo.aiProposed')}
              </div>
            </div>
            <div className={styles.sectionDescription}>{task.modifiedTask}</div>
          </div>
        )}

        {task.motivation && !isToBeFormulated && task.status !== TaskStatus.Done && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>{t('page.taskInfo.whyImportantNow')}</div>
            </div>
            <div className={styles.sectionDescription}>{task.motivation}</div>
          </div>
        )}

        {task.status === TaskStatus.Postponed && !isEmpty(task.comment) && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionTitle}>{t('page.taskInfo.postponeComment')}</div>
            </div>
            <div className={styles.sectionDescription}>{task.comment}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default TaskInfo;
