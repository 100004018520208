import styles from '../../ExchangerWidget.module.scss';

import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import { useIsSwapPossible } from '../../hooks';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';

export function  ExchangeSwapButton({
  providerId,
  userId,
}: ExchangerWidgetProps): JSX.Element | null {
  const {
    currentFormData,
    tabs,
    calculateExchangePaymentForm,
    activeOption,
    isInitialization,
    inputTarget,
  } = useExchangerStore(providerId, userId);
  const currencies = tabs?.find(t => t.name === activeOption)?.currencies;
  const isSwapPossible = useIsSwapPossible({
    currentFormData,
    currencies,
  });

  if (isInitialization) {
    // FIXME: add skeleton
    return (
      <SkeletonWithWrapper
        circle
        height={32}
        containerClassName={styles.swap}
      />
    );
  }

  const swapExchangeData = () => {
    if (currentFormData && isSwapPossible) {
      const nextInputTarget = inputTarget === 'sell' ? 'buy' : 'sell';
      calculateExchangePaymentForm({
        sellExchangeUnit: currentFormData.buyExchangeUnit,
        sellExchangeUnitAmount: currentFormData.buyExchangeUnitAmount,
        buyExchangeUnit: currentFormData.sellExchangeUnit,
        buyExchangeUnitAmount: currentFormData.sellExchangeUnitAmount,
      }, `${nextInputTarget}ExchangeUnitAmount`, nextInputTarget);
    }
  };

  if (activeOption !== 'Currency') {
    return null;
  }

  return (
    <button
      type="button"
      onClick={swapExchangeData}
      disabled={!isSwapPossible}
      className={styles.swap}
    >
      <Icon name={IconName.Switch} width={32} height={32} />
    </button>
  );
}
