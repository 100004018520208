import React, { useEffect, useState } from 'react';
import { Layout } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import styles from './Notifications.module.scss';
import NotificationBanner from 'components/NotificationBanner';
import {
  useChangeAllUserSubscriptionsMutation, useChangeUserMuteModeSubscriptionsMutation,
  useGetUserMuteModeQuery,
  useGetUserSubscriptionsQuery,
} from 'services/notifications';
import Checkbox from 'components/Checkbox/Checkbox';
import { NotificationEvent, UserSubscriptionDto } from 'dtos';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import Toggle from 'components/Toggle';
import cn from 'classnames';
import { toast } from 'react-hot-toast';
import GoBackButton from '../../../components/Layout/GoBackButton';

const balancesEvents = [NotificationEvent.balanceTransaction, NotificationEvent.newBalance, NotificationEvent.newBalanceAssistant, NotificationEvent.newBalanceName];
const exchangeEvents = [NotificationEvent.newExchange, NotificationEvent.exchangeCompleted, NotificationEvent.exchangeCanceled, NotificationEvent.exchangeUpdatedStatus];
const invitationEvents = [NotificationEvent.invitationAccept, NotificationEvent.invitationExpired, NotificationEvent.invitationAlreadyUsed];

function Notifications(): JSX.Element {
  const { t } = useTranslation();
  const { data: userSubscriptions, isLoading } = useGetUserSubscriptionsQuery();
  const { data: muteMode, isLoading: isLoadingMuteMode } = useGetUserMuteModeQuery();
  const [changeUserMuteMode] = useChangeUserMuteModeSubscriptionsMutation();
  const [changeAll] = useChangeAllUserSubscriptionsMutation();
  const [userMuteMode, setUserMuteMode] = useState(muteMode ?? false);

  const balancesSubscriptions = userSubscriptions?.filter((subscription) =>
    balancesEvents.includes(subscription.notificationEvent)
  ) ?? [];
  const exchangeSubscriptions = userSubscriptions?.filter((subscription) =>
    exchangeEvents.includes(subscription.notificationEvent)
  ) ?? [];
  const invitationSubscriptions = userSubscriptions?.filter((subscription) =>
    invitationEvents.includes(subscription.notificationEvent)
  ) ?? [];
  const bulkSubscriptions = userSubscriptions?.filter((subscription) =>
    subscription.notificationEvent === NotificationEvent.bulkNotifications,
  ) ?? [];

  const balancesBot = getEventBoolean(balancesSubscriptions, true);
  const balancesPush = getEventBoolean(balancesSubscriptions, false);
  const exchangeBot = getEventBoolean(exchangeSubscriptions, true);
  const exchangePush = getEventBoolean(exchangeSubscriptions, false);
  const invitationBot = getEventBoolean(invitationSubscriptions, true);
  const invitationPush = getEventBoolean(invitationSubscriptions, false);
  const bulkBot = getEventBoolean(bulkSubscriptions, true);
  const bulkPush = getEventBoolean(bulkSubscriptions, false);

  const [balancesBotState, setBalancesBotState] = useState(balancesBot);
  const [balancesPushState, setBalancesPushState] = useState(balancesPush);
  const [exchangeBotState, setExchangeBotState] = useState(exchangeBot);
  const [exchangePushState, setExchangePushState] = useState(exchangePush);
  const [invitationBotState, setInvitationBotState] = useState(invitationBot);
  const [invitationPushState, setInvitationPushState] = useState(invitationPush);
  const [bulkBotState, setBulkBotState] = useState(bulkBot);
  const [bulkPushState, setBulkPushState] = useState(bulkPush);

  useEffect(() => {
    if (balancesBot !== balancesBotState) {
      setBalancesBotState(balancesBot);
    }
    if (balancesPush !== balancesPushState) {
      setBalancesPushState(balancesPush);
    }
    if (exchangeBot !== exchangeBotState) {
      setExchangeBotState(exchangeBot);
    }
    if (exchangePush !== exchangePushState) {
      setExchangePushState(exchangePush);
    }
    if (invitationBot !== invitationBotState) {
      setInvitationBotState(invitationBot);
    }
    if (invitationPush !== invitationPushState) {
      setInvitationPushState(invitationPush);
    }
    if (bulkBot !== bulkBotState) {
      setBulkBotState(bulkBot);
    }
    if (bulkPush !== bulkPushState) {
      setBulkPushState(bulkPush);
    }
  }, [balancesBot, balancesPush, exchangeBot, exchangePush, invitationBot, invitationPush, bulkBot, bulkPush]);

  const changeMuteMode = () => {
    changeUserMuteMode(!userMuteMode).unwrap()
      .then(() => setUserMuteMode(!userMuteMode))
      .catch(() => toast.error(t('error.unknown')));
  }

  const sendChangedSubscriptions = (changedSubscriptions: UserSubscriptionDto[], currentState: boolean, setState: (state: boolean) => void) => {
    changeAll(changedSubscriptions).unwrap()
      .then(() => setState(!currentState))
      .catch(() => toast.error(t('error.unknown')));
  }

  const changeBalancesBot = () => {
    const updatedEvents = balancesSubscriptions.map((event) => ({
      ...event,
      bot: !balancesBotState,
    }));
    sendChangedSubscriptions(updatedEvents, balancesBotState, setBalancesBotState)
  };
  const changeBalancesPush = () => {
    const updatedEvents = balancesSubscriptions.map((event) => ({
      ...event,
      push: !balancesPushState,
    }));
    sendChangedSubscriptions(updatedEvents, balancesPushState, setBalancesPushState)
  };

  const changeExchangeBot = () => {
    const updatedEvents = exchangeSubscriptions.map((event) => ({
      ...event,
      bot: !exchangeBotState,
    }));
    sendChangedSubscriptions(updatedEvents, exchangeBotState, setExchangeBotState);
  };
  const changeExchangePush = () => {
    const updatedEvents = exchangeSubscriptions.map((event) => ({
      ...event,
      push: !exchangePushState,
    }));
    sendChangedSubscriptions(updatedEvents, exchangePushState, setExchangePushState);
  };

  const changeInvitationBot = () => {
    const updatedEvents = invitationSubscriptions.map((event) => ({
      ...event,
      bot: !invitationBotState,
    }));
    sendChangedSubscriptions(updatedEvents, invitationBotState, setInvitationBotState);

  };
  const changeInvitationPush = () => {
    const updatedEvents = invitationSubscriptions.map((event) => ({
      ...event,
      push: !invitationPushState,
    }));
    sendChangedSubscriptions(updatedEvents, invitationPushState, setInvitationPushState);
  };

  const changeBulkBot = () => {
    const updatedEvents = bulkSubscriptions.map((event) => ({
      ...event,
      bot: !bulkBotState,
    }));
    sendChangedSubscriptions(updatedEvents, bulkBotState, setBulkBotState);
  };

  const changeBulkPush = () => {
    const updatedEvents = bulkSubscriptions.map((event) => ({
      ...event,
      push: !bulkPushState,
    }));
    sendChangedSubscriptions(updatedEvents, bulkPushState, setBulkPushState);
  };

  if (isLoading || isLoadingMuteMode) {
    return (
      <Layout>
        <Layout.TopBar>
          <Layout.Title>{t('page.notifications.title')}</Layout.Title>
        </Layout.TopBar>

        <Layout.Content>
          <NotificationBanner className={styles.banner} />

          <SkeletonWithWrapper height={50} className={styles.marginBottom}/>
          <SkeletonWithWrapper height={300} />
        </Layout.Content>
      </Layout>
    );
  }

  if (userMuteMode === true) {
    return (
      <Layout>
        <Layout.TopBar>
          <Layout.Title>{t('page.notifications.title')}</Layout.Title>
        </Layout.TopBar>

        <Layout.Content>
          <NotificationBanner className={styles.banner} />

          <div className={styles.toggle}>
            <span className={styles.eventTitle}>{t('page.notifications.switchOff')}</span>
            <Toggle isToggled={userMuteMode} setIsToggled={changeMuteMode} />
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Layout.TopBar>
        <GoBackButton />
        <Layout.Title>{t('page.notifications.title')}</Layout.Title>
      </Layout.TopBar>

      <Layout.Content>
        <NotificationBanner />

        <div className={styles.toggle}>
          <span className={styles.eventTitle}>{t('page.notifications.switchOff')}</span>
          <Toggle isToggled={userMuteMode} setIsToggled={changeMuteMode} />
        </div>

        <div className={styles.table}>
          <div className={styles.row}>
            <div className={cn(styles.cell, styles.flexible, styles.translucent)}>{t('page.notifications.type')}</div>
            <div className={cn(styles.cell, styles.fixed, styles.translucent, styles.borderLeft)}>
              <label className={styles.checkbox}>{t('page.notifications.bot')}</label>
            </div>
            <div className={cn(styles.cell, styles.fixed, styles.translucent, styles.borderLeft)}>
              <label className={styles.checkbox}>{t('page.notifications.push')}</label>
            </div>
          </div>

          <div className={styles.row}>
            <div className={cn(styles.cell, styles.flexible)}>{t('page.notifications.balances')}</div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeBalancesBot} checked={balancesBotState} labelClassName={styles.checkbox} />
            </div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeBalancesPush} checked={balancesPushState} labelClassName={styles.checkbox} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={cn(styles.cell, styles.flexible)}>{t('page.notifications.exchanges')}</div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeExchangeBot} checked={exchangeBotState} labelClassName={styles.checkbox} />
            </div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeExchangePush} checked={exchangePushState} labelClassName={styles.checkbox} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={cn(styles.cell, styles.flexible)}>{t('page.notifications.invitations')}</div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeInvitationBot} checked={invitationBotState} labelClassName={styles.checkbox} />
            </div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeInvitationPush} checked={invitationPushState} labelClassName={styles.checkbox} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={cn(styles.cell, styles.flexible)}>{t('page.notifications.bulk')}</div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeBulkBot} checked={bulkBotState} labelClassName={styles.checkbox} />
            </div>
            <div className={cn(styles.cell, styles.fixed, styles.borderLeft)}>
              <Checkbox onChangeValue={changeBulkPush} checked={bulkPushState} labelClassName={styles.checkbox} />
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );

}

function getEventBoolean(subscriptions: UserSubscriptionDto[], bot: boolean): boolean {
  let response: boolean;
  if (bot) {
    response = subscriptions.length !== 0 ? subscriptions.every(subscription => subscription.bot) : false;
  } else {
    response = subscriptions.length !== 0 ? subscriptions.every(subscription => subscription.push) : false;
  }
  return response;
}

export default Notifications;
