import { createApi } from '@reduxjs/toolkit/query/react';
import { ResellType, UserDto } from 'dtos';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getMe: builder.query<UserDto, void>({
      query: () => '/users/me',
      keepUnusedDataFor: 120,
      providesTags: [{ type: 'User' }],
    }),
    resellStrategy: builder.mutation<UserDto, ResellType>({
      query: (strategy) => ({
        url: '/users/me/resell-strategy',
        method: 'POST',
        body: { strategy },
      }),
      invalidatesTags: ['User'],
    }),
    changeLanguage: builder.mutation<UserDto, UserDto['language']>({
      query: (locale) => ({
        url: '/users/me/language-preferences',
        method: 'POST',
        body: { locale },
      }),
      invalidatesTags: ['User'],
    }),
    becomeProvider: builder.mutation<UserDto, void>({
      query: () => ({
        url: '/users/me/become-provider',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    })
  }),
});

export const { useGetMeQuery, useLazyGetMeQuery, useResellStrategyMutation, useChangeLanguageMutation, useBecomeProviderMutation } = usersApi;
