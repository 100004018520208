import React, { useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/Layout/Layout';
import TaskPageHeader from './components/TaskPageHeader';
import MainTask from './components/MainTask';
import AllTask from './components/AllTask';
import { PaymentStatus, TaskStatus } from 'dtos';
import { useGetTaskListQuery, usePrioritizeTasksMutation } from 'services/tasks';
import { ModalTypes, open } from 'slices/modal';
import TaskFormulatingModal from 'widgets/TaskFormulatingModal';
import styles from './styles.module.scss';
import TaskList from './components/TaskList';
import { FOCUS_TASK, PRIORITIZE } from 'const';
import PaymentsHistoryWidget from 'widgets/PaymentsHistoryWidget';
import { useGetInfinitePayments } from 'entities1/payments/model/services/useGetInfinitePayments';
import { Link, useNavigate } from 'react-router-dom';
import {
  usePersistedPaymentsByStatus,
  usePersistedPaymentsFilterByRole,
  usePersistedPaymentsFilterByType,
} from 'entities1/payments/model/hooks';

const statuses = [
  TaskStatus.ReadyForWork,
  TaskStatus.ToBeFormulated,
  TaskStatus.Postponed,
  TaskStatus.InProgress,
  TaskStatus.Done,
];

const TaskMainPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clearSelectedRoles } = usePersistedPaymentsFilterByRole();
  const { clearSelectedStatuses } = usePersistedPaymentsByStatus();
  const { clearSelectedTypes } = usePersistedPaymentsFilterByType();
  const [prioritizeTasks, { isLoading: isPrioritizing }] = usePrioritizeTasksMutation();
  const { data: taskListData, isLoading: isTaskListLoading } = useGetTaskListQuery({
    statuses,
    page: 1,
    limit: 1000,
  });
  const tasks = useMemo(() => {
    return taskListData?.content || [];
  }, [taskListData?.content]);

  const readyForWorkTasks = tasks.filter((task) => task.status === TaskStatus.ReadyForWork);
  const inProgressTasks = tasks.filter((task) => task.status === TaskStatus.InProgress);

  const handleTransactionalTaskButton = () => {
    clearSelectedRoles();
    clearSelectedStatuses();
    clearSelectedTypes();
    navigate('/payments');
  };

  const prioritizeButtonClick = async () => {
    try {
      await prioritizeTasks();
    } catch (e) {
      Sentry.captureException(e);
      if (!(e instanceof Error)) return;
      toast.error(e.message);
    }
  };

  const openTaskFormulationModal = () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: {},
      })
    );
  };

  useEffect(() => {
    localStorage.removeItem(FOCUS_TASK);
    const isNeedPrioritize = JSON.parse(localStorage.getItem(PRIORITIZE) || 'false');
    if (isNeedPrioritize) {
      prioritizeButtonClick();
      localStorage.removeItem(PRIORITIZE);
    }
  }, []);

  const {
    data: paymentsData,
    fetchNextPage,
    isLoading: isPaymentsLoading,
    isFetchingNextPage,
  } = useGetInfinitePayments({
    queryKey: ['payments'],
    filterable: true,
    filters: { paymentStatuses: [PaymentStatus.Initialized, PaymentStatus.InProgress] },
  });
  const hasTransactionalTasks = paymentsData?.pages[0]
    ? paymentsData?.pages[0].content?.length > 0
    : false;

  return (
    <Layout>
      <Layout.TopBar unsetBottomPadding>
        <TaskPageHeader
          openTaskFormulationModal={openTaskFormulationModal}
          isLoading={isTaskListLoading || isPrioritizing}
        />
      </Layout.TopBar>
      <Layout.Content unsetTopPadding unsetLeftRightPadding className={styles.content}>
        <MainTask
          readyForWorkTasks={readyForWorkTasks.concat(inProgressTasks)}
          isLoadingTaskList={isTaskListLoading}
          onPrioritizeButtonClick={prioritizeButtonClick}
          isPrioritizing={isPrioritizing}
          openTaskFormulationModal={openTaskFormulationModal}
        />

        {tasks.length > 0 && (
          <div className={styles.gap}>
            <AllTask isPrioritizing={isPrioritizing} />
          </div>
        )}

        <TaskList
          tasks={tasks}
          isProcessing={isPrioritizing}
          onPrioritizeButtonClick={prioritizeButtonClick}
        />

        {hasTransactionalTasks && (
          <>
            <div className={styles.title}>
              <h3>{t('page.task.list.transactional')}</h3>
              <Link
                to={'/payments'}
                // variant={Variant.Gray}
                className={styles.link}
                onClick={handleTransactionalTaskButton}
              >
                {t('page.task.seeAll')}
              </Link>
            </div>
            <PaymentsHistoryWidget
              data={paymentsData}
              isLoading={isPaymentsLoading}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              onlyTransactions
            />
          </>
        )}

        <TaskFormulatingModal />
      </Layout.Content>
    </Layout>
  );
};

export default TaskMainPage;
