import styles from './index.module.scss';

import { FC } from 'react';

import { ExchangerWidgetProps } from '../../ExchangerWidget';
import { useExchangerStore } from '../../store';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { Slider } from 'shared/ui/Slider';
import head from 'lodash/head';
import { SubcategorySlide } from './SubcategorySlide';
import { GoodsDto, SubcategoryDtoV4 } from 'dtos';
import { useGoodsInfo } from 'widgets/ExchangerWidget/hooks/useGetGoodsInfo';

type SubcategoryDtoV4WithId = SubcategoryDtoV4 & {
  id: number;
};

export const ExchangerSubcategories: FC<ExchangerWidgetProps> = (
  props
) => {
  const { providerId, userId } = props;
  const {
    activeOption,
    tabs,
    currentFormData,
    calculateExchangePaymentForm,
    isInitialization,
  } = useExchangerStore(providerId, userId);
  const goods = tabs?.find(t => t.name === activeOption)?.goods;

  const { currentCity } = useGoodsInfo({
    good: currentFormData?.buyExchangeUnit as GoodsDto,
    cities: goods,
    subcategory: currentFormData?.subcategory,
  });

  const currentSubcategory = currentFormData?.subcategory;
  const subcategories = currentCity?.subcategories.map((sc, i) => ({
    ...sc,
    id: i,
  }));
  const subcategory = subcategories?.find(
    (sc) => sc.subcategoryName === currentSubcategory
  );

  if (isInitialization) {
    // FIXME: add skeleton
    return <SkeletonWithWrapper className={styles.list} height={20} />;
  }

  if (!currentSubcategory || !subcategories) {
    return <></>;
  }

  const changeSubcategory = (subcategory?: SubcategoryDtoV4WithId) => {
    if (!subcategory) {
      return;
    }

    const model = head(subcategory.skuModels);

    if (!model) {
      return;
    }

    calculateExchangePaymentForm({
      buyExchangeUnit: {
        name: model.modelName,
        city: currentFormData?.buyExchangeUnit?.city,
        measureUnit: model.measureUnits[0].name,
        skuParameters: model.availableSkuParameters.map((p) => ({
          name: p.name,
          localizedName: p.localizedName,
          type: p.type,
          value: p.availableValues[0].value,
          localizedValue: p.availableValues[0].localizedValue
        })),
      },
      subcategory: subcategory.subcategoryName,
    });
  };

  return (
    <div className={styles.root}>
      <Slider
        slide={subcategory}
        slides={subcategories}
        onSlideChange={changeSubcategory}
        slideRenderer={SubcategorySlide}
        sliderClassName={styles.slider}
        slideClassName={styles.slide}
      />
    </div>
  );
};
