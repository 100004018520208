import { useTranslation } from 'react-i18next';
import Button, { Variant } from 'components/Button/Button';

import styles from './TaskList.module.scss';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TaskResponseDto, TaskStatus } from 'dtos';
import toast from 'react-hot-toast';
import { ModalTypes, open } from 'slices/modal';
import { useChangePostponedToReadyForWorkStatusMutation } from 'services/tasks';

type TaskListProps = {
  tasks: TaskResponseDto[];
  isProcessing: boolean;
  onPrioritizeButtonClick: () => void;
};

const TaskList = (props: TaskListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tasks, isProcessing, onPrioritizeButtonClick } = props;
  const [unblockTask, { isLoading: isUnblockLoading }] =
    useChangePostponedToReadyForWorkStatusMutation();

  const disabledButton = isUnblockLoading || isProcessing;

  const recentlyAddedTasks = tasks
    .filter((task) => task.status === TaskStatus.ReadyForWork)
    .filter((task) => !task.isPrioritized);
  const draftTasks = tasks.filter((task) => task.status === TaskStatus.ToBeFormulated);
  const blockedTasks = tasks.filter((task) => task.status === TaskStatus.Postponed);
  const inProgressTasks = tasks.filter((task) => task.status === TaskStatus.InProgress);

  const handleSetTaskButtonClick = async (task: TaskResponseDto) => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: {
          task,
          isNeedInitialAssessment: true,
        },
      })
    );
  };

  const handlePrioritizeButtonClick = async () => {
    onPrioritizeButtonClick();
  };

  const handleUnblockButtonClick = async (id: TaskResponseDto['id']) => {
    await toast.promise(unblockTask(id), {
      loading: t('status.processing'),
      success: () => {
        return <b>{t('status.success')}</b>;
      },
      error: () => <b>{t('status.smthWrong')}</b>,
    });
  };

  const handleTaskClick = useCallback(
    (id: TaskResponseDto['id']) => {
      navigate(`/task/${id}`);
    },
    [navigate]
  );

  return (
    <>
      {recentlyAddedTasks.length > 0 && (
        <>
          <div className={styles.title}>
            <h3>{t('page.task.list.recently')}</h3>
            {inProgressTasks.length === 0 && (
              <Button
                variant={Variant.Gray}
                className={styles.button}
                onClick={handlePrioritizeButtonClick}
                disabled={disabledButton}
              >
                {t('page.task.list.prioritize')}
              </Button>
            )}
          </div>
          <ul>
            {recentlyAddedTasks.map((task) => {
              return (
                <li key={task.id} className={styles.task} onClick={() => !disabledButton && handleTaskClick(task.id)}>
                  <span className={styles.taskText}>{task.title || task.originalTask}</span>
                </li>
              );
            })}
          </ul>
        </>
      )}
      {blockedTasks.length > 0 && (
        <>
          <h3 className={styles.title}>{t('page.task.list.blocked')}</h3>
          <ul>
            {blockedTasks.map((task) => {
              return (
                <li key={task.id} className={styles.task} onClick={() => !disabledButton && handleTaskClick(task.id)}>
                  <span className={styles.taskText}>{task.title || task.originalTask}</span>
                  <Button
                    variant={Variant.Gray}
                    className={styles.button}
                    disabled={disabledButton}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleUnblockButtonClick(task.id);
                    }}
                  >
                    {t('page.task.list.unblock')}
                  </Button>
                </li>
              );
            })}
          </ul>
        </>
      )}
      {draftTasks.length > 0 && (
        <>
          <h3 className={styles.title}>{t('page.task.list.draft')}</h3>
          <ul>
            {draftTasks.map((task) => {
              return (
                <li key={task.id} className={styles.task} onClick={() => !disabledButton && handleTaskClick(task.id)}>
                  <span className={styles.taskText}>{task.title || task.originalTask}</span>
                  <Button
                    variant={Variant.Gray}
                    className={styles.button}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSetTaskButtonClick(task);
                    }}
                    disabled={disabledButton}
                  >
                    {t('page.task.list.setTask')}
                  </Button>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default TaskList;
